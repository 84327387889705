import React, { useEffect, useRef, useState, useMemo } from "react";
import "./roomc.css";
import MeetingActionButton from "./../../components/meetingActionButton/MeetingActionButton";
import CopyMeetingId from "./../../components/copyMeetingId/CopyMeetingId";
import { LuUsers2 } from "react-icons/lu";
import PaginationIcon from "../../components/paginationIcon/PaginationIcon";
import { IoMicOffOutline } from "react-icons/io5";
import { IoVideocamOffOutline } from "react-icons/io5";
import ReactPlayer from "react-player";
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp } from "react-icons/fa6";

const Roomc = () => {
  const [items, setItems] = useState([
    { name: "fsf" },
    { name: "sdv" },

  ]);
  const [mainItem, setMainItem] = useState({  });
  const [maxItemCountInMainItem, setMaxItemCountInMainItem] = useState(0);
  const [startingIndexInMainPage, setStartingIndexInMainPage] = useState(0);

  const [displayItems, setDisplayItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [showNextItemsButton, setShowNextItemsButton] = useState(false);
  const [showPreviousItemsButton, setShowPreviousItemsButton] = useState(false);

  const middleContainerRef = useRef(null);
  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  });

  const updateContainerDimensions = () => {
    if (middleContainerRef.current) {
      setContainerDimensions({
        width: middleContainerRef.current.offsetWidth - 60,
        height: middleContainerRef.current.offsetHeight - 10,
      });
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateContainerDimensions);
    updateContainerDimensions();

    return () =>
      window.removeEventListener("resize", updateContainerDimensions);
  }, []);

  const itemWidth = useMemo(() => {
    if (items.length > 24) return 110;
    if (items.length > 18) return 150;
    if (items.length > 10) return 200;
    if (items.length > 4) return 250;
    return 250;
  }, [items.length]);

  const itemHeight = useMemo(() => itemWidth * 0.6, [itemWidth]);

  const maxItemsCount = useMemo(() => {
    const itemsPerRow = Math.floor(
      containerDimensions.width / (itemWidth + 10)
    );
    const rows = Math.floor(containerDimensions.height / (itemHeight + 10));
    return itemsPerRow * rows;
  }, [containerDimensions, itemWidth, itemHeight]);

  const itemsPerPage = maxItemsCount;

  useEffect(() => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setDisplayItems(items.slice(startIndex, endIndex));

    setShowNextItemsButton(endIndex < items.length);
    setShowPreviousItemsButton(startIndex > 0);
  }, [currentPage, itemsPerPage, items]);

  useEffect(() => {
    const rows = Math.floor(containerDimensions.height / (150 + 10));
    setMaxItemCountInMainItem(rows);
  }, [containerDimensions]);

  const itemStyle = {
    display: "flex",
    width: `${itemWidth}px`,
    height: `${itemHeight}px`,
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #000",
    overflow: "hidden",
    position: "relative",
  };
  return (
    <div
      className="room_container"
      style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <div className="top_room_container d-flex align-items-center justify-content-between">
        <div style={{ fontWeight: "bold", color: "gray" }}>Morning Meeting</div>

        <div className="d-flex align-items-center justify-content-center">
          <div>
            <LuUsers2 size={20} />
          </div>
          <div
            style={{ fontSize: "14px", marginTop: "3px", marginLeft: "2px" }}>
            22
          </div>
        </div>
      </div>
      <div className="middle_room_container" ref={middleContainerRef}>
        {Object.keys(mainItem).length === 0 && (
          <div
            style={{
              height: "100%",
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
              position: "relative",
            }}>
            {" "}
            {showNextItemsButton && (
              <PaginationIcon
                side="right"
                onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
              />
            )}
            {showPreviousItemsButton && (
              <PaginationIcon
                side="left"
                onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
              />
            )}
            {displayItems.map((item, index) => (
              <div key={index} style={itemStyle}>
                {" "}
                <div
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    left: "0px",
                    fontSize: "14px",
                  }}>
                  Nalaka Sampath
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "3px",
                    right: "5px",
                    display: "flex",
                    gap: "3px",
                  }}>
                  <div>
                    <IoMicOffOutline size={16} />
                  </div>
                  <div>
                    <IoVideocamOffOutline size={16} />
                  </div>{" "}
                </div>
                <ReactPlayer
                  playing
                
                  // height={"500%"}
                  // url={"https://youtu.be/9DEv2Bwjju4?si=xHpKeCoyKBBAlYtR"}
                />
                {/* {item.name} */}
              </div>
            ))}
          </div>
        )}
       
      </div>
      <div
        className="bottom_room_container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "8px 40px",
        }}>
        <div>
          {/* <CopyMeetingId t /> */}
        </div>
        <div>
          <div className="d-flex justify-content-center align-items-center gap-3">
            <MeetingActionButton disabled={true} action={"audio"} />
            <MeetingActionButton disabled={true} action={"video"} />
            <MeetingActionButton action={"screenShare"} />
            <MeetingActionButton action={"message"} />
            <MeetingActionButton action={"react"} />
          </div>
        </div>
        <div>
          <div className="leave_button d-flex justify-content-center align-items-center">
            <div>Leave</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roomc;
