import React, { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdOutlineContentCopy } from "react-icons/md";
import tooltip from "tooltip";
const CopyMeetingId = ({ meetingId, onClick, meetingData, clickedCopy }) => {
  useEffect(() => {
    const config = {
      showDelay: 100,
      style: {
        padding: 2,
        backgroundColor: "#000",
        color: "#fff",
        borderRadius: "3px",
      },
      offset: { x: 0, y: 10 },
    };

    tooltip(config);
  }, []);
  return (
    <div>
      {" "}
      <div
        className=" d-flex align-items-center gap-2 px-2"
        style={{
          backgroundColor: "white",
          color: "gray",
          height: "30px",
          borderRadius: "5px",
          fontSize: "15px",
        }}>
        <div>
          <div>{meetingId}</div>
        </div>
        <div
          style={{
            height: "25px",
            width: "2px",
            backgroundColor: "gray",
          }}></div>
        <CopyToClipboard
          text={`invitation for the ${meetingData.title} 
meeting ID ${meetingData.id}
meeting URL https://zoom.hasthiya.org/lobby/${meetingData.id}`}
          onCopy={clickedCopy}>
          <div
            onClick={onClick}
            style={{ cursor: "pointer" }}
            data-tooltip="Click to copy invitation">
            <MdOutlineContentCopy size={18} />
          </div>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default CopyMeetingId;
