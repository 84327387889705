import React, { useEffect, useState } from "react";
import TextInput from "../../components/TextInput/TextInput";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { toast, Zoom } from "react-toastify";
import { MdOutlineContentCopy } from "react-icons/md";
import MeetingButton from "../../components/meetingButton/MeetingButton";
import { AiFillHome } from "react-icons/ai";
import Swal from "sweetalert2";
import "./lobby.css";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import tooltip from "tooltip";
const Lobby = () => {
  const { meetingId } = useParams();
  const [meeting_Id, setMeeting_Id] = useState(meetingId);
  const [meetingIdError, setMeetingIdError] = useState("");
  const [meetingData, setMeetingData] = useState({});
  const [isCreatedUser, setIsCreatedUser] = useState(false);
  const [foundMeeting, setFoundMeeting] = useState(false);
  const [date, setDate] = useState("");
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("token"));
  useEffect(() => {
    const config = {
      showDelay: 100,
      style: {
        padding: 2,
        backgroundColor: "#000",
        color: "#fff",
        borderRadius: "3px",
      },
      offset: { x: 0, y: 10 },
    };

    tooltip(config);
  }, []);
  const clickedCopy = () => {
    console.log("copied");
    toast(" 📄 Invitation copied", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Zoom,
    });
  };
  const getMeetingDetails = async () => {
    try {
      const meeting = await axios.get(
        `https://webapi.zoom.hasthiya.org/meeting/getMeeting/${meeting_Id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (meeting.data.meeting.status) {
        setMeetingData(meeting.data.meeting.result);
        setIsCreatedUser(meeting.data.meeting.isCreatedUser);
        if (meeting.data.meeting.result.type === "oneTime") {
          const utcDate = new Date(meeting.data.meeting.result.dateAndTime);
          const istDate = utcDate.toLocaleTimeString("en-IN", {
            year: "numeric",
            month: "long",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
            timeZone: "Asia/Kolkata",
          });
          setDate(istDate);
          console.log(istDate);

          // setTime(istTime);
        }
        setFoundMeeting(true);
      }
      console.log(meeting.data.meeting.result);
    } catch (error) {
      console.log(error.response.status);
      if (error.response.status === 401) {
       navigate("/")
      }
      setFoundMeeting(false);
      setMeetingData({});
    }
  };
  useEffect(() => {
    getMeetingDetails();
  }, [meeting_Id]);

  const handleDeleteClick = () => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0f2d52",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const respond = await axios.delete(
            `https://webapi.zoom.hasthiya.org/meeting/deleteMeeting/${meetingData.id}`
          );
          if (respond.data.success) {
            Swal.fire({
              title: "Deleted!",
              text: "Your report has been deleted.",
              icon: "success",
              customClass: {
                confirmButton: "custom-swal-button",
                container: "custom-swal-container",
              },
            });
            setTimeout(() => {
              navigate("/home");
            }, 2000);
          }
        } catch (error) {
          console.error("Error deleting item:", error);
          Swal.fire("Error", "Failed to delete report.", "error");
        }
      }
    });
  };
  return (
    <div>
      <div
        style={{
          paddingTop: "100px",
          backgroundColor: "#202124",
          height: "100vh",
        }}
        className=" d-flex justify-content-center">
        <div>
          <div className=" d-flex justify-content-center mb-2 ">
            <div
              onClick={() => navigate("/home")}
              className=" d-flex justify-content-center align-items-center home_button">
              {" "}
              <AiFillHome />
            </div>
          </div>
          <div
            style={{
              width: "500px",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
            className=" p-3">
            <div style={{ fontWeight: "550", fontSize: "16px" }}>
              Meeting Code
            </div>
            <TextInput
              type={"text"}
              placeholder={"Enter meeting code"}
              value={meeting_Id}
              onChange={(value) => setMeeting_Id(value)}
              errorMessage={meetingIdError}
              onFocus={() => setMeetingIdError("")}
            />
            {foundMeeting ? (
              <div
                style={{ border: "1px solid #c2c2c2", borderRadius: "5px" }}
                className="  mt-3 p-2">
                <div className=" d-flex justify-content-between align-items-center">
                  <div style={{ fontWeight: "600" }}></div>
                  <div className=" d-flex align-items-center gap-1">
                    <div className="meeting_card_icons">
                      {isCreatedUser && (
                        <dov>
                          <MdOutlineDeleteOutline
                            size={20}
                            onClick={() => handleDeleteClick()}
                          />
                        </dov>
                      )}
                    </div>
                    {/* <div className="meeting_card_icons">
                      <MdOutlineEdit size={20} />
                    </div> */}
                    <CopyToClipboard
                      text={
`invitation for the ${meetingData.title} 
meeting ID ${meetingData.id}
meeting URL https://zoom.hasthiya.org/lobby/${meetingData.id}
`}
                      onCopy={clickedCopy}>
                      <div
                        data-tooltip="Copy Invitation"
                        className="meeting_card_icons">
                        <MdOutlineContentCopy size={16} />
                      </div>
                    </CopyToClipboard>
                  </div>
                </div>
                <div style={{ fontWeight: "600" }}>{meetingData.title}</div>

                {meetingData.type === "oneTime" ? (
                  <div
                    style={{
                      fontSize: "14px",
                      color: "gray",
                      display: "flex",
                    }}>
                    <div>{date}</div>{" "}
                    <div>{` | ${meetingData.duration} min`}</div>
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: "14px",
                      color: "gray",
                      display: "flex",
                    }}>
                    Reusable meeting
                  </div>
                )}
                <div className=" d-flex justify-content-center mt-2">
                  <div style={{ width: "100px" }}>
                    {" "}
                    <MeetingButton
                      icon={false}
                      text={"Start"}
                      type={"dark"}
                      onClick={() => {
                        navigate(`/room/${meeting_Id}`, {
                          replace: true,
                          state: token,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{ border: "1px solid #c2c2c2", borderRadius: "5px" }}
                className="  mt-3 p-4 d-flex justify-content-center">
                <div>No matching meetings!</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lobby;
