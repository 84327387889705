import React from "react";
import "./paginationIcon.css";
import { FaChevronLeft, FaAngleRight } from "react-icons/fa6";
const PaginationIcon = ({ side, onClick }) => {
  if (side == "left") {
    return (
      <div
        onClick={onClick}
        className="pagination_icon_container"
        style={{ position: "absolute", left: "10px" }}>
        <FaChevronLeft size={20} />
      </div>
    );
  } else {
    return (
      <div
        onClick={onClick}
        className="pagination_icon_container"
        style={{ position: "absolute", right: "10px" }}>
        <FaAngleRight size={20} />
      </div>
    );
  }
};

export default PaginationIcon;
