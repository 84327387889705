import React, { useEffect, useState } from "react";
import { HiOutlineVideoCamera } from "react-icons/hi2";
import MeetingButton from "../../components/meetingButton/MeetingButton";
import "./meetingCard.css"
import { useNavigate } from "react-router-dom";

const MeetingCard = ({ meeting }) => {

  const [time, setTime] = useState("");
  const navigate=useNavigate()


  useEffect(() => {
    const calculateDateAndTime = () => {
      if (meeting.type === "oneTime") {
        const utcDate = new Date(meeting.dateAndTime);
        const istTime = utcDate.toLocaleTimeString("en-IN", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
          timeZone: "Asia/Kolkata",
        });
     
        console.log(istTime);

        setTime(istTime);
      }
    }
    calculateDateAndTime();

},[])
  
  console.log(meeting)

  return (
    <div
      onClick={() => navigate(`/lobby/${meeting.id}`)}
      className="d-flex justify-content-center mt-2 meeting_card_container">
      <div
        className="meeting_card"
        style={{
          height: "60px",
          width: "500px",
          backgroundColor: "#f6f7f8",
          borderRadius: "5px",
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
          overflow: "hidden",
        }}>
        <div className="d-flex gap-2">
          <HiOutlineVideoCamera
            style={{ marginTop: "3px", color: "gray", marginRight: "5px" }}
            size={18}
          />
          <div
            style={{
              overflow: "hidden",
              width: "350px",
            }}>
            <div style={{ fontWeight: "550", width: "330px" }}>
              {meeting.title}
            </div>
            {meeting.type === "oneTime" && (
              <div
                className="d-flex gap-2"
                style={{ color: "gray", fontSize: "14px" }}>
                <div>{time}</div>|<div>{meeting.duration} min</div>
              </div>
            )}
          </div>
        </div>

        <div style={{ width: "100px" }}>
          <MeetingButton
            type={"dark"}
            text={"Start"}
            icon={false}
            // onClick={createMeeting}
            // loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default MeetingCard;
