
import "./welcome.css";
import logo from "./../../images/Group 128.png";
import homeImage from "../../images/home.png";
import Button from "../../components/Button/Button";
import { motion } from "framer-motion";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const WelcomePage = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const sendAuthRequest = async () => {
    console.log(token);
    if (!token) {
      navigate("/signIn");
      return;
    }
    setLoading(true);
    try {
      const authData = await axios.post(
        "https://webapi.zoom.hasthiya.org/user/userAuthenticationHome",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(authData.data.success);
      if (authData.data.success) {
          setTimeout(() => {
            navigate("/home");
          }, 1000);
      }
    } catch (error) {
      console.log(error.response.data.success);
      if (!error.response.data.success) {
        setTimeout(() => {
      navigate("/signIn");
        },1000)
      }
    } finally {
      setTimeout(() => {
      setLoading(false);   
      },1000)
    }
  };
  return (
    <div style={{ overflow: "hidden" }}>
      <div
        className="home_container"
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <motion.div
          initial={{ x: -1000, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1, type: "spring", stiffness: 50 }}
          className=" d-flex home_content ">
          <div className="mt-4 ">
            <img src={logo} alt="logo" />
            <div className="home_main_text">
              <div>Connect and Collaborate</div>
              <div>
                {" "}
                <span className="Seamlessly">Seamlessly</span> , No Matter
              </div>
              <div> Where You Are</div>
            </div>

            <div style={{ color: "gray" }} className=" mt-3 home_text">
              Discover the seamless collaboration experience. Wherever you are,
              connect effortlessly. Join us and revolutionize your meetings.
              Together, let's redefine communication.
            </div>
            <div style={{ width: "150px", marginTop: "60px" }}>
              <Button loading={loading} onClick={sendAuthRequest} text={"Start"} type={"1"} />
            </div>
          </div>
        </motion.div>
        <motion.div
          className="home_image"
          initial={{ x: 1000, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1, type: "spring", stiffness: 50 }}>
          <img style={{ height: "100vh" }} src={homeImage} alt="" />
        </motion.div>
      </div>
    </div>
  );
};

export default WelcomePage;
