import React, { useEffect, useState } from "react";
import "./SignUp.css";
import TextInput from "../../components/TextInput/TextInput";
import Button from "../../components/Button/Button";
// import Swal from "sweetalert2";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "./../../images/Group 128.png";
import googleLogo from ".././../images/google.png";
import fbLogo from ".././../images/fb.png";
import { toast, Zoom } from "react-toastify";
import { motion } from "framer-motion";

const SignUp = () => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  //errors
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const signUpButtonClicked = async () => {
    if (!firstName) {
      setFirstNameError("Enter first name");
    } else if (!lastName) {
      setLastNameError("Enter last name");
    } else if (!email) {
      setEmailError("Enter email");
    } else if (!validator.isEmail(email)) {
      setEmailError("Enter valid email");
    } else if (!password) {
      setPasswordError("Enter password");
    } else if (!validator.isLength(password, { min: 8 })) {
      setPasswordError("Password must be at least 8 characters long");
    } else {
      setLoading(true);
      await axios
        .post("https://webapi.zoom.hasthiya.org/user/register", {
          firstName,
          lastName,
          email,
          password,
        })
        .then((res) => {
          if (res.data.success) {
            localStorage.setItem("token", JSON.stringify(res.data.token));
            toast.success("Registration successful", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              transition: Zoom,
            });
          } else {
            toast.error(res.massage, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              transition: Zoom,
            });
          }
          setTimeout(() => {
            navigate("/home");
          }, 2000);
        })
        .catch((err) => {
          console.log(err.response.data.success);
          console.log(err.response.data.message);
          if (err.response.data.success === false) {
            toast.error(err.response.data.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              transition: Zoom,
            });
          } else {
            toast.error("Registration error. Please try again", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              transition: Zoom,
            });
          }
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 3000);
        });
    }
  };

  return (
    <div className="signUpPage">
      <motion.div
        className="logoContainer"
        initial={{ x: -1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}>
        <img src={logo} alt="" />
      </motion.div>

      <div className="boxContainer">
        <div className="imageContainer">
          <div className="imageBox">
            <div className="imgContainerText">
              <h2 className="whiteH2">Create your account</h2>
              <p className="signUpPageText">
                Connect instantly with high-quality video meetings.
              </p>
            </div>
          </div>
        </div>
        <div className="signInBox">
          <div className="d-flex" style={{ height: "480px" }}>
            <div style={{ padding: "30px" }} className=" w-100">
              <div>
                <h2 className="header2 mb-2">Create your account</h2>
                <span className="small">Already have an account ?</span>
                <span
                  className="small fw-bold ms-1 login_register_navigate_btn"
                  onClick={() => navigate("/signIn")}>
                  Sign In
                </span>
                <div
                  className="d-flex mt-4 auth_buttons"
                  style={{ justifyContent: "space-between" }}>
                  <div
                    className="d-flex justify-content-center align-items-center p-2 auth_container"
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #c2c2c2",
                      width: "49%",
                    }}>
                    <img
                      className=" me-1"
                      style={{ width: "20px" }}
                      src={googleLogo}
                      alt=""
                    />
                    <div className="small auth_text">Sign in with Google</div>
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center p-2 auth_container"
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #c2c2c2",
                      width: "49%",
                    }}>
                    <img
                      className=" me-1"
                      style={{ width: "24px" }}
                      src={fbLogo}
                      alt=""
                    />
                    <div className="small auth_text">Sign in with Facebook</div>
                  </div>
                </div>
                <div
                  className=" text-center mt-4 mb-4"
                  style={{ color: "#666666", fontSize: "14px" }}>
                  -OR-
                </div>

                <div className="d-flex justify-content-between">
                  <div style={{ width: "49%" }}>
                    <TextInput
                      type={"text"}
                      icon={"profile"}
                      placeholder={"First name"}
                      value={firstName}
                      onChange={(value) => setFirstName(value)}
                      errorMessage={firstNameError}
                      onFocus={() => setFirstNameError("")}
                    />
                  </div>
                  <div style={{ width: "49%" }}>
                    <TextInput
                      type={"text"}
                      icon={"profile"}
                      placeholder={"Last name"}
                      value={lastName}
                      onChange={(value) => setLastName(value)}
                      errorMessage={lastNameError}
                      onFocus={() => setLastNameError("")}
                    />
                  </div>
                </div>

                <div className=" mt-2">
                  <TextInput
                    type={"email"}
                    icon={"mail"}
                    placeholder={"Email"}
                    value={email}
                    onChange={(value) => setEmail(value)}
                    errorMessage={emailError}
                    onFocus={() => setEmailError("")}
                  />
                </div>
                <div className=" mt-2">
                  <TextInput
                    type={"password"}
                    icon={"lock"}
                    placeholder={"Password"}
                    value={password}
                    onChange={(value) => setPassword(value)}
                    errorMessage={passwordError}
                    onFocus={() => setPasswordError("")}
                  />
                </div>
                <div className=" mt-4">
                  <Button
                    loading={loading}
                    type={"1"}
                    text="Sign Up"
                    onClick={signUpButtonClicked}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
