import React from "react";
import { HiOutlineVideoCamera } from "react-icons/hi2";
import "./meetingButton.css"
import MoonLoader from "react-spinners/MoonLoader";
const MeetingButton = ({ type, text,onClick,icon,loading }) => {
  return (
    <div onClick={onClick}>
      {loading ? (
        <div
          className={`d-flex justify-content-center align-items-center gap-2 ${
            type == "dark" ? "meeting_btn_dark" : "meeting_btn_light"
          }`}>
          <MoonLoader
            color={"#ffffff"}
            loading={loading}
            size={16.5}
            aria-label="Loading Spinner"
            data-testid="loader"
            speedMultiplier={1}
          />
        </div>
      ) : (
        <div
          className={`d-flex justify-content-center align-items-center gap-2 ${
            type == "dark" ? "meeting_btn_dark" : "meeting_btn_light"
          }`}>
          {" "}
          {icon && (
            <div>
              <HiOutlineVideoCamera size={20} />
            </div>
          )}
          <div>{text}</div>
        </div>
      )}
    </div>
  );
};

export default MeetingButton;
