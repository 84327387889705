import React, { useEffect, useState } from "react";
import "./home.css";
import logo from "./../../images/Group 128.png";
import profile from "./../../images/profile.png";

import MeetingButton from "../../components/meetingButton/MeetingButton";
import ChatModal from "../../components/meetingModal/MeetingModal";
import axios from "axios";

import MeetingDates from "../../components/meetingDates/MeetingDates";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [showCreateMeetingModal, setShowCreateMeetingModal] = useState(false);
  const [meetingsData, setMeetingsData] = useState({});
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("token"));
  useEffect(() => {
    getMeetingData();
  }, []);
  const getMeetingData = async () => {
    try {
      const meetingData = await axios.get(
        "https://webapi.zoom.hasthiya.org/meeting/getAllMeeting",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (meetingData.data.success) {
        console.log(meetingData.data);
        setMeetingsData(meetingData.data.allMeetings);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        navigate("/");
      }
    }
  };

  return (
    <div>
      <div>
        {showCreateMeetingModal && (
          <div>
            <ChatModal
              setShowCreateMeetingModal={setShowCreateMeetingModal}
              getMeetingData={getMeetingData}
            />
          </div>
        )}
      </div>
      <div
        className="home_Background"
        style={{
          display: "flex",
          position: "relative",
          height: "70vh",
          minHeight: "250px",
        }}>
        <div className=" w-100">
          <div className=" d-flex justify-content-between">
            <img
              onClick={() => navigate("/")}
              className=" mt-3 ms-5"
              src={logo}
              alt=""
            />
            <img
              onClick={() => navigate("/")}
              className=" mt-3 me-5"
              style={{ width: "40px", height: "40px", cursor: "pointer" }}
              src={profile}
              alt=""
            />
          </div>

          <div style={{ position: "absolute", bottom: "40px", width: "100%" }}>
            <div className=" d-flex justify-content-center align-items-center">
              <div
                style={{
                  height: "220px",
                  width: "650px",
                  borderRadius: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(213, 229, 245, 0.358)",
                  backdropFilter: "blur(5px)",
                }}>
                <div>
                  {" "}
                  <div
                    style={{
                      fontSize: "28px",
                      fontWeight: "600",
                      color: "#1d2545",
                      textAlign: "center",
                    }}>
                    <div>Seamless Video Conferencing</div>
                    <div>with CHATVISTA</div>
                  </div>
                  <div
                    style={{
                      margin: "0 15%",
                      textAlign: "center",
                      lineHeight: "1.4",
                      color: "#1d2545",
                    }}>
                    Connect seamlessly in our virtual space, where collaboration
                    thrives and ideas ignite. Join us for productive meetings
                    today!
                  </div>
                  <div className=" d-flex justify-content-center gap-2 mt-3">
                    <div style={{ width: "180px" }}>
                      <MeetingButton
                        icon={true}
                        text={"Create a meeting"}
                        type={"dark"}
                        onClick={() => {
                          setShowCreateMeetingModal(true);
                        }}
                      />
                    </div>
                    <div style={{ width: "180px" }}>
                      <MeetingButton
                        icon={true}
                        text={"Join a meeting"}
                        type={"light"}
                        onClick={() => navigate("/lobby")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" d-flex justify-content-center  mb-5">
        <div
          style={{
            width: "700px",
            minHeight: "27vh",
            margin: "10px 0px 0px 0px",
            backgroundColor: "#e1f5fb",
            borderRadius: "10px",
            paddingBottom: "20px",
          }}>
          <div className=" d-flex justify-content-center mb-3">
            <div>
              <div
                style={{ fontWeight: "700", color: "#1d2545" }}
                className=" text-center mt-2">
                Upcoming
              </div>
              <div className=" d-flex justify-content-center align-items-end">
                <div
                  style={{
                    height: "1px",
                    width: "70px",
                    backgroundColor: "#1d2545",
                  }}></div>
                <div
                  style={{
                    height: "5px",
                    width: "70px",
                    backgroundColor: "#1d2545",
                    borderRadius: "2px",
                  }}></div>
                <div
                  style={{
                    height: "1px",
                    width: "70px",
                    backgroundColor: "#1d2545",
                  }}></div>
              </div>
            </div>
          </div>
          <div>
            {" "}
            {Object.keys(meetingsData).map((meetingDate) => {
              if (meetingDate === "reusable") {
                return (
                  <MeetingDates
                    date={"Reusable Meetings"}
                    key={meetingDate}
                    meetings={meetingsData[meetingDate]}
                  />
                );
              }
            })}
            {Object.keys(meetingsData).map((meetingDate) => {
              if (meetingDate !== "reusable") {
                return (
                  <MeetingDates
                    date={meetingDate}
                    key={meetingDate}
                    meetings={meetingsData[meetingDate]}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
