import React, { useState } from "react";
import TextInput from "../TextInput/TextInput";
import MeetingButton from "../meetingButton/MeetingButton";
import { toast, Zoom } from "react-toastify";
import axios from "axios";
const MeetingModal = ({ setShowCreateMeetingModal, getMeetingData }) => {
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [duration, setDuration] = useState("");

  const [titleError, setTitleError] = useState("");
  const [dateError, setDateError] = useState("");
  const [timeError, setTimeError] = useState("");
  const [durationError, setDurationError] = useState("");

  const [loading, setLoading] = useState(false);

  const handleRadioChange = (e, type) => {
    if (e.target.value == "on") {
      console.log(type);
      setType(type);
    }
  };
  const token = JSON.parse(localStorage.getItem("token"));
  const createMeeting = async () => {
    if (!title) {
      setTitleError("Please enter a title");
      return;
    }
    if (!type) {
      toast.error("Please select a meeting type", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Zoom,
      });
      return;
    }
    if (type === "oneTime") {
      const numbersRegex = /^[0-9]+$/;
      if (!date) {
        setDateError("Please enter a date");
        return;
      }
      if (!time) {
        setTimeError("Please enter a time");
        return;
      }
      if (!duration) {
        setDurationError("Please enter a duration");
        return;
      }
      if (!numbersRegex.test(duration)) {
        setDurationError("Duration must be a number");
        return;
      }
    }
    setLoading(true);

    try {
      const response = await axios.post(
        "https://webapi.zoom.hasthiya.org/meeting/createMeeting",
        { title, type, date, time, duration },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.result.status);
      if (response.data.result.status) {
        getMeetingData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
        setShowCreateMeetingModal(false);
      }, 1000);
    }
  };

  return (
    <div>
      <div
        onClick={() => setShowCreateMeetingModal(false)}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
          overflow: "auto",
          cursor: "pointer",
        }}>
        <div
          onClick={(e) => e.stopPropagation()}
          style={{
            cursor: "default",
            backgroundColor: "white",
            padding: "20px 30px",
            borderRadius: "6px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            width: "500px",
          }}>
          <div
            style={{ fontSize: "20px", fontWeight: "550", color: "#1d2545" }}>
            Create meeting
          </div>
          <div className=" mt-3">
            <TextInput
              type={"text"}
              placeholder={"Meeting title"}
              value={title}
              onChange={(value) => setTitle(value)}
              errorMessage={titleError}
              onFocus={() => setTitleError("")}
            />
          </div>
          <div className=" mt-3" style={{ fontWeight: "600" }}>
            Meeting type
          </div>
          <div
            className=" mt-2 p-3 d-flex   "
            style={{
              border: "1px solid #c2c2c2",
              borderRadius: "5px",
              width: "100%",
            }}>
            <div className="d-flex" style={{ width: "100%" }}>
              <input
                onChange={(e) => handleRadioChange(e, "oneTime")}
                style={{
                  width: "18px",
                  height: "18px",
                  cursor: "pointer",
                  marginTop: "3px",
                  backgroundColor: "black",
                }}
                type="radio"
                name="type"
              />
              <div className="ms-2" style={{ width: "100%" }}>
                <div style={{ fontWeight: "550", fontSize: "" }}>
                  One-time Meeting
                </div>
                <div
                  className=" mt-2"
                  style={{ fontSize: "14px", fontWeight: "550" }}>
                  Date
                </div>
                <div style={{ width: "100%" }}>
                  <TextInput
                    type={"date"}
                    value={date}
                    onChange={(value) => setDate(value)}
                    errorMessage={dateError}
                    onFocus={() => setDateError("")}
                  />
                </div>
                <div className=" d-flex w-100 gap-2 mt-2">
                  <div style={{ width: "100%" }}>
                    <div style={{ fontSize: "14px", fontWeight: "550" }}>
                      Time
                    </div>
                    <div style={{ width: "100%" }}>
                      <TextInput
                        type={"time"}
                        value={time}
                        onChange={(value) => setTime(value)}
                        errorMessage={timeError}
                        onFocus={() => setTimeError("")}
                      />
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <div style={{ fontSize: "14px", fontWeight: "550" }}>
                      Duration (min)
                    </div>
                    <div style={{ width: "100%" }}>
                      <TextInput
                        type={"text"}
                        placeholder={"Duration"}
                        value={duration}
                        onChange={(value) => setDuration(value)}
                        errorMessage={durationError}
                        onFocus={() => setDurationError("")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className=" mt-3 p-3 d-flex   "
            style={{
              border: "1px solid #c2c2c2",
              borderRadius: "5px",
              width: "100%",
            }}>
            <div className="d-flex" style={{ width: "100%" }}>
              <input
                onChange={(e) => handleRadioChange(e, "reusable")}
                style={{
                  width: "18px",
                  cursor: "pointer",
                  height: "18px",
                  marginTop: "3px",
                  backgroundColor: "black",
                }}
                type="radio"
                name="type"
              />
              <div className="ms-2" style={{ width: "100%" }}>
                <div style={{ fontWeight: "550" }}>Reusable Meeting</div>
              </div>
            </div>
          </div>
          <div className=" d-flex justify-content-end gap-2 mt-4">
            {" "}
            <div style={{ width: "100px" }}>
              <MeetingButton
                type={"light"}
                text={"Cancel"}
                icon={false}
                onClick={() => setShowCreateMeetingModal(false)}
              />
            </div>
            <div style={{ width: "100px" }}>
              <MeetingButton
                type={"dark"}
                text={"Save"}
                icon={false}
                onClick={createMeeting}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingModal;
