import React, { useEffect } from "react";
import "./meetingActionButton.css";
import { IoMicOutline } from "react-icons/io5";
import { IoVideocamOutline } from "react-icons/io5";
import { MdOutlineScreenShare } from "react-icons/md";
import { RiMessage2Line } from "react-icons/ri";
import { CiFaceSmile } from "react-icons/ci";
import { IoMicOffOutline } from "react-icons/io5";
import { TbVideoOff } from "react-icons/tb";
import tooltip from "tooltip";
import { GoDotFill } from "react-icons/go";

import { BsGrid3X2Gap } from "react-icons/bs";
const MeetingActionButton = ({
  onClick,
  action,
  disabled,
  tooltipText,
  showDot,
}) => {
  useEffect(() => {
    const config = {
      showDelay: 100,
      style: {
        padding: 2,
        backgroundColor: "#000",
        color: "#fff",
        borderRadius: "3px",
      },
      offset: { x: 0, y: 10 },
    };

    tooltip(config);
  }, []);

  return (
    <div
      data-tooltip={tooltipText}
      onClick={onClick}
      className={`meeting_action_button_container ${
        disabled === true ? "meeting_action_button_container_disabled" : ""
      }`}>
      {showDot && (
        <div
          style={{
            position: "absolute",
            top: "-5px",
            right: "0",
            color: "red",
          }}>
          <GoDotFill />
        </div>
      )}

      <div className="meeting_action_button_icon">
        {action === "audio" && disabled == false && <IoMicOutline size={20} />}
        {action === "audio" && disabled == true && (
          <IoMicOffOutline size={20} />
        )}
        {action === "video" && disabled == false && (
          <IoVideocamOutline size={20} />
        )}
        {action === "video" && disabled == true && <TbVideoOff size={20} />}
        {action === "screenShare" && <MdOutlineScreenShare size={20} />}
        {action === "message" && <RiMessage2Line size={20} />}
        {action === "react" && <CiFaceSmile size={20} />}
        {action === "listView" && <BsGrid3X2Gap size={20} />}
      </div>
    </div>
  );
};

export default MeetingActionButton;
