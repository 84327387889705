import { Routes, Route } from "react-router-dom";
import "./App.css";
// import LobbyScreen from "./screens/Lobby";
import "bootstrap/dist/css/bootstrap.min.css";
import RoomPage from "./screens/room/Room.jsx";
import SignUp from "./screens/signUp/SignUp";
import SignIn from "./screens/signIn/SignIn";
import Welcome from "./screens/welcome/WelcomePage.jsx"
import Home from "./screens/home/Home.jsx";
import Lobby from "./screens/lobby/Lobby.jsx";
import Roomc from "./screens/roomc/Roomc.jsx";



function App() {
  return (
    <div className="App">
      <Routes>
        {/* <Route path="/lobby" element={<LobbyScreen />} /> */}
        <Route path="/room/:roomId" element={<RoomPage />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/signIn" element={<SignIn />} />
        <Route path="/" element={<Welcome />} />
        <Route path="/home" element={<Home />} />
        <Route path="/lobby/:meetingId?" element={<Lobby />} />
        <Route path="/roomc" element={<Roomc />} />










      </Routes>
    </div>
  );
}

export default App;
