import React from "react";
import profile from "./../../images/profile.png";
const LeftMessage = ({ message, showUserData }) => {
  return (
    <div>
      <div className={`d-flex  mb-1 gap-2 ${showUserData ? "mt-2" : ""}`}>
        {showUserData ? (
          <div>
            <img
              style={{
                width: "32px",
                height: "32px",
                marginTop: "20px",
              }}
              src={profile}
              alt=""
            />
          </div>
        ) : (
          <div style={{ width: "32px" }}></div>
        )}
        <div>
          {showUserData && (
            <div
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                fontFamily: "font5",
              }}>
              {message.name}
            </div>
          )}
          <div className="">
            <div
              style={{
                padding: "6px 8px",
                textAlign: "justify",
                borderRadius: "5px",
                maxWidth: "400px",
                backgroundColor: "#cce6ffdd",
                fontSize: "14px",
                fontFamily: "font5",
              }}>
              <div style={{ fontSize: "10px", color: "#383838dd" }}>
                {message && message.time}
              </div>
              {message && message.message}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftMessage;
