import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from "react";
import ReactPlayer from "react-player";
import PeerService from "../../service/peer";
import { useSocket } from "../../context/SocketProvider";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import Swal from "sweetalert2";

//!new

import { toast, Zoom } from "react-toastify";
import "./room.css";
import MeetingActionButton from "./../../components/meetingActionButton/MeetingActionButton";
import CopyMeetingId from "./../../components/copyMeetingId/CopyMeetingId";
import { LuUsers2 } from "react-icons/lu";
import PaginationIcon from "./../../components/paginationIcon/PaginationIcon";
import { IoMicOffOutline } from "react-icons/io5";
import { IoVideocamOffOutline } from "react-icons/io5";
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp } from "react-icons/fa6";
import { MdFullscreen } from "react-icons/md";
import axios from "axios";
import ChatModal from "../../components/chatModal/ChatModal";
const RoomPage = () => {
  const { roomId } = useParams();
  const location = useLocation();
  const { state } = location;
  const socket = useSocket();
  const [peerConnections, setPeerConnections] = useState([]);
  const [showChatModal, setShowChatModal] = useState(false);
  const [messages, setMessages] = useState([]);
  const [myStream, setMyStream] = useState();
  const [remoteStreams, setRemoteStreams] = useState([]);
  const [isVideoOn, setIsVideoOn] = useState(false);
  const [isAudioOn, setIsAudioOn] = useState(false);
  const [isScreenShareOn, setIsScreenShareOn] = useState(false);
  const [myScreenShare, setMyScreenShare] = useState(null);
  const [myScreenSharePeerConnections, setMyScreenSharePeerConnections] =
    useState([]);
  const [incomingScreenShare, setIncomingScreenShare] = useState(null);
  const [incomingScreenVideo, setIncomingScreenVideo] = useState(null);
  const [readMessageCount, setReadMessageCount] = useState(0);
  const [meetingData, setMeetingData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (!state) {
      navigate("/");
      return;
    }
    getMeetingDetails();
    socket.emit("room:join", {
      room: roomId,
      // name: name ? name : "Gust user",
      token: state,
    });
  }, []);

  const getMeetingDetails = async () => {
    try {
      const meeting = await axios.get(
        `https://webapi.zoom.hasthiya.org/meeting/getMeeting/${roomId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state}`,
          },
        }
      );
      if (meeting.data.meeting.status) {
        setMeetingData(meeting.data.meeting.result);
      }
      console.log(meeting.data.meeting.result);
    } catch (error) {
      console.log(error);
      setMeetingData({});
    }
  };

  // console.log(state, "state: ");

  const handleOtherUsersInThisRoom = useCallback(
    async ({ otherUsersInThisRoom }) => {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      stream.getVideoTracks().forEach((track) => (track.enabled = false));
      stream.getAudioTracks().forEach((track) => (track.enabled = false));
      setMyStream(stream);
      console.log("otherUsersInThisRoom", otherUsersInThisRoom);
      otherUsersInThisRoom.forEach((user) => {
        handleCallUser(user);
      });
    },
    []
  );

  const handleUserJoined = useCallback(
    (data) => {
      toast(`${data.name} joined`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Zoom,
      });
      console.log("joinedUserId", data);
      if (myScreenShare) {
        handleCallUserForScreen(data);
      }
      // window.alert(`${data.name} is joined`);
    },
    [myScreenShare]
  );

  const handleCallUser = useCallback(
    async (user) => {
      console.log("handleCallUser", user);
      const peerService = new PeerService();
      const offer = await peerService.getOffer();
      setPeerConnections((prev) => [
        ...prev,
        { id: user.socket, name: user.name, peer: peerService },
      ]);
      socket.emit("user:call", {
        to: user.socket,
        token: state,
        offer,
      });
    },
    [socket]
  );
  const handleCallUserForScreen = useCallback(
    async (data) => {
      console.log("handleCallUserForScreen", data);
      const peerService = new PeerService();
      const offer = await peerService.getOffer();
      const name = localStorage.getItem("userName");
      const newConnection = {
        id: data.socket,
        name: data.name,
        peer: peerService,
      };
      myScreenSharePeerConnections.push(newConnection);

      socket.emit("user:callForScreen", {
        to: data.socket,
        token: state,
        offer,
      });
    },
    [socket]
  );

  const handleIncomingCall = useCallback(
    async ({ from, offer, name }) => {
      console.log(`Incoming Call`, name, offer);
      const peerService = new PeerService();
      const ans = await peerService.getAnswer(offer);
      setPeerConnections((prev) => [
        ...prev,
        { id: from, name, peer: peerService },
      ]);
      socket.emit("call:accepted", { to: from, ans });
    },
    [socket]
  );

  const handleIncomingCallForScreen = useCallback(
    async ({ from, offer, name }) => {
      console.log(`handleIncommingCallForScreen`, from, offer, name);
      const peerService = new PeerService();
      const ans = await peerService.getAnswer(offer);
      setIncomingScreenShare({ id: from, name, peer: peerService });
      socket.emit("call:acceptedForScreen", { to: from, ans });
    },
    [socket]
  );

  const handleIncomingCallExisting = useCallback(
    async ({ from, offer }) => {
      console.log(`Incoming Call`, from, offer);
      const peerService = new PeerService();
      const ans = await peerService.getAnswer(offer);
      setPeerConnections((prev) => [
        ...prev,
        { id: `existing_${from}`, peer: peerService },
      ]);
      socket.emit("call:accepted", { to: from, ans });
    },
    [socket]
  );
  const handleCallAccepted = useCallback(
    async ({ from, ans }) => {
      console.log("handleCallAccepted", peerConnections, from, ans);
      peerConnections.forEach((peer) => {
        if (peer.id == from) {
          console.log(peer, "accepted");
          peer.peer.setLocalDescription(ans);
          console.log("Call Accepted! sendStreams");

          sendStreams(from);
        }
      });
    },
    [peerConnections, myStream]
  );

  const sendStreamsForScreen = useCallback(
    (id) => {
      console.log("sendStreamsForScreen", myScreenSharePeerConnections, id);

      setMyScreenShare((prev) => {
        myScreenSharePeerConnections.forEach((peer) => {
          if (peer.id === id) {
            console.log(peer.peer, "in for", id);
            const senders = peer.peer.peer.getSenders();
            const existingSender = senders.find(
              (sender) => sender.track === prev.getTracks()[0]
            );
            if (!existingSender) {
              console.log("send");
              for (const track of prev.getTracks()) {
                peer.peer.peer.addTrack(track, prev);
              }

              handlePeerConnectionEvents(peer);
            }
          }
        });
        return prev;
      });
    },
    [myScreenSharePeerConnections]
  );

  const handleCallAcceptedForScreen = useCallback(
    async ({ from, ans }) => {
      console.log(
        "handleCallAcceptedForScreen",
        myScreenSharePeerConnections,
        from,
        ans
      );

      setMyScreenSharePeerConnections((prev) => {
        prev.forEach((peer) => {
          if (peer.id == from) {
            console.log(peer, "accepted");
            peer.peer.setLocalDescription(ans);
            console.log("Call Accepted! sendStreams");

            sendStreamsForScreen(from);
          }
        });
        return prev;
      });
    },
    [myScreenSharePeerConnections, sendStreamsForScreen, myStream]
  );
  const sendStreams = useCallback(
    (id) => {
      console.log("sendStreams", peerConnections, id);

      peerConnections.forEach((peer) => {
        if (peer.id === id) {
          console.log(peer.peer, "in for");
          const senders = peer.peer.peer.getSenders();
          const existingSender = senders.find(
            (sender) => sender.track === myStream.getTracks()[0]
          );
          if (!existingSender) {
            for (const track of myStream.getTracks()) {
              peer.peer.peer.addTrack(track, myStream);
            }
          }
        }
      });
    },
    [myStream, peerConnections]
  );

  const handleNegoNeeded = useCallback(
    async (id) => {
      console.log("handleNegoNeeded", id);
      const peerToUpdate = peerConnections.find((peer) => peer.id == id);
      if (peerToUpdate) {
        console.log("peerToUpdate");
        const offer = await peerToUpdate.peer.getOffer();
        socket.emit("peer:nego:needed", { offer, to: id });
      }
    },
    [peerConnections, socket]
  );

  const handleNegoNeededForScreen = useCallback(
    async (id) => {
      console.log("handleNegoNeededForScreen", id);
      const peerToUpdate = myScreenSharePeerConnections.find(
        (peer) => peer.id == id
      );
      if (peerToUpdate) {
        console.log("peerToUpdate");
        const offer = await peerToUpdate.peer.getOffer();
        socket.emit("peer:nego:neededForScreen", { offer, to: id });
      }
    },
    [myScreenSharePeerConnections, socket]
  );

  useEffect(() => {
    peerConnections.forEach((peer) => {
      if (peer.peer.peer) {
        const id = peer.id;
        console.log(id, "peer id");
        peer.peer.peer.addEventListener("negotiationneeded", () =>
          handleNegoNeeded(id)
        );
      }
    });

    return () => {
      peerConnections.forEach((peer) => {
        if (peer.peer.peer) {
          const id = peer.id;
          peer.peer.peer.removeEventListener("negotiationneeded", () =>
            handleNegoNeeded(id)
          );
        }
      });
    };
  }, [peerConnections, handleNegoNeeded]);

  useEffect(() => {
    myScreenSharePeerConnections.forEach((peer) => {
      if (peer.peer.peer) {
        const id = peer.id;
        console.log(id, "peer id");
        peer.peer.peer.addEventListener("negotiationneeded", () =>
          handleNegoNeededForScreen(id)
        );
      }
    });

    return () => {
      myScreenSharePeerConnections.forEach((peer) => {
        if (peer.peer.peer) {
          const id = peer.id;
          peer.peer.peer.removeEventListener("negotiationneeded", () =>
            handleNegoNeededForScreen(id)
          );
        }
      });
    };
  }, [
    myScreenSharePeerConnections,
    setMyScreenSharePeerConnections,
    handleNegoNeededForScreen,
  ]);

  const handlePeerConnectionEvents = (peer) => {
    if (peer.peer.peer) {
      const id = peer.id;
      console.log(id, "peer id");
      peer.peer.peer.addEventListener("negotiationneeded", () =>
        handleNegoNeededForScreen(id)
      );
    }

    return () => {
      if (peer.peer.peer) {
        const id = peer.id;
        peer.peer.peer.removeEventListener("negotiationneeded", () =>
          handleNegoNeededForScreen(id)
        );
      }
    };
  };

  const handleNegoNeedIncoming = useCallback(
    async ({ from, offer }) => {
      const peerToUpdate = peerConnections.find((peer) => peer.id === from);
      if (peerToUpdate) {
        const ans = await peerToUpdate.peer.getAnswer(offer);
        socket.emit("peer:nego:done", { to: from, ans });
      }
    },
    [peerConnections, socket]
  );

  const handleNegoNeedIncomingForScreen = useCallback(
    async ({ from, offer }) => {
      console.log("handleNegoNeedIncomingForScreen");
      console.log("1aaa");

      setIncomingScreenShare(async (prev) => {
        console.log("aaaa", prev);
        const ans = await prev.peer.getAnswer(offer);
        socket.emit("peer:nego:doneForScreen", { to: from, ans });

        return prev;
      });
    },
    [incomingScreenShare, socket]
  );

  const handleNegoNeedFinal = useCallback(
    async ({ ans, from }) => {
      console.log("handleNegoNeedFinal");
      const peerToUpdate = peerConnections.find((peer) => peer.id === from);
      if (peerToUpdate) {
        await peerToUpdate.peer.setLocalDescription(ans);
        socket.emit("start:streaming", { to: from });
      }
    },
    [peerConnections, socket]
  );

  const handleNegoNeedFinalForScreen = useCallback(
    async ({ ans, from }) => {
      console.log("handleNegoNeedFinalForScreen");
      const peerToUpdate = myScreenSharePeerConnections.find(
        (peer) => peer.id === from
      );
      if (peerToUpdate) {
        await peerToUpdate.peer.setLocalDescription(ans);
      }
    },
    [peerConnections, socket]
  );

  const handleStartStreaming = useCallback(() => {
    console.log("handleStartStreaming");
    if (myStream) {
      peerConnections.forEach((peer) => {
        sendStreams(peer.id);
      });
    }
  }, [myStream, peerConnections, sendStreams]);

  const handleUserDisconnected = useCallback(
    async ({ socketId }) => {
      console.log("disconnected", socketId);
      peerConnections.forEach((peer) => {
        if (peer.id === socketId) {
          peer.peer.peer.close();
        }
      });
      setMainItem((mainItem) => {
        if (mainItem.id == socketId) {
          return {};
        }
        return mainItem;
      });
      setPeerConnections((prev) => prev.filter((peer) => peer.id !== socketId));
      setRemoteStreams((prev) =>
        prev.filter((stream) => stream.id !== socketId)
      );
      setIncomingScreenVideo((prev) => {
        console.log(prev, "pred");

        if (prev && prev.id == socketId) {
          setIncomingScreenShare(null);
          return null;
        }
        return prev;
      });
    },
    [peerConnections, incomingScreenShare, incomingScreenVideo]
  );

  const handleUsersInThisRoom = useCallback(async ({ usersInThisRoom }) => {
    console.log("usersInThisRoom", usersInThisRoom);

    usersInThisRoom.forEach((user) => {
      handleCallUserForScreen(user);
    });
  }, []);

  const handleUserStopMyScreen = useCallback(
    async ({ from }) => {
      console.log("handleUserStopMyScreen", from);
      setMainItem({});
      setIncomingScreenVideo((prev) => {
        if (prev != null) {
          console.log(prev, "pred");
          if (prev.id == from) {
            setIncomingScreenShare(null);
            return null;
          }
        }
        return null;
      });
    },
    [incomingScreenVideo, setIncomingScreenShare]
  );

  useEffect(() => {
    socket.on("otherUsersInThisRoom", handleOtherUsersInThisRoom);
    socket.on("userJoined", handleUserJoined);
    socket.on("incoming:call", handleIncomingCall);
    socket.on("call:accepted", handleCallAccepted);
    socket.on("peer:nego:needed", handleNegoNeedIncoming);
    socket.on("peer:nego:final", handleNegoNeedFinal);
    socket.on("start:streaming1", handleStartStreaming);
    socket.on("incomming:call:existing", handleIncomingCallExisting);
    socket.on("user:disconnected", handleUserDisconnected);
    socket.on("usersInThisRoom", handleUsersInThisRoom);
    socket.on("incomming:callForScreen", handleIncomingCallForScreen);
    socket.on("call:acceptedForScreen", handleCallAcceptedForScreen);
    socket.on("peer:nego:neededForScreen", handleNegoNeedIncomingForScreen);
    socket.on("peer:nego:finalForScreen", handleNegoNeedFinalForScreen);
    socket.on("userStopMyScreen", handleUserStopMyScreen);
    socket.on("user:isVideoOn", handleUserToggleVideo);
    socket.on("user:isAudioOn", handleUserToggleAudio);
    socket.on("receiveMessage", handleReceiveMessage);

    return () => {
      socket.off("otherUsersInThisRoom", handleOtherUsersInThisRoom);
      socket.off("userJoined", handleUserJoined);
      socket.off("incoming:call", handleIncomingCall);
      socket.off("call:accepted", handleCallAccepted);
      socket.off("peer:nego:needed", handleNegoNeedIncoming);
      socket.off("peer:nego:final", handleNegoNeedFinal);
      socket.off("start:streaming1", handleStartStreaming);
      socket.off("incomming:call:existing", handleIncomingCallExisting);
      socket.off("user:disconnected", handleUserDisconnected);
      socket.off("usersInThisRoom", handleUsersInThisRoom);
      socket.off("incomming:callForScreen", handleIncomingCallForScreen);
      socket.off("call:acceptedForScreen", handleCallAcceptedForScreen);
      socket.off("peer:nego:neededForScreen", handleNegoNeedIncomingForScreen);
      socket.off("peer:nego:finalForScreen", handleNegoNeedFinalForScreen);
      socket.off("userStopMyScreen", handleUserStopMyScreen);
      socket.off("user:isVideoOn", handleUserToggleVideo);
      socket.off("user:isAudioOn", handleUserToggleAudio);
      socket.off("receiveMessage", handleReceiveMessage);
    };
  }, [
    socket,
    handleOtherUsersInThisRoom,
    handleUserJoined,
    handleIncomingCall,
    handleCallAccepted,
    handleNegoNeedIncoming,
    handleNegoNeedFinal,
    handleStartStreaming,
  ]);

  useEffect(() => {
    peerConnections.forEach((peer) => {
      peer.peer.peer.addEventListener("track", async (ev) => {
        const remoteStream = ev.streams[0];
        if (remoteStream.getVideoTracks().length > 0) {
          console.log("Got video track", remoteStream.id, peer, "peer id");
          setRemoteStreams((prevStreams) => {
            const existingStream = prevStreams.find(
              (stream) => stream.remoteStream.id == remoteStream.id
            );
            if (!existingStream) {
              return [
                ...prevStreams,
                { remoteStream, id: peer.id, name: peer.name },
              ];
            } else {
              return prevStreams;
            }
          });
        } else {
          console.log("Got non-video track");
        }
      });
    });
  }, [peerConnections]);

  useEffect(() => {
    if (incomingScreenShare && incomingScreenShare.peer) {
      incomingScreenShare.peer.peer.addEventListener("track", async (ev) => {
        const remoteStream = ev.streams[0];
        if (remoteStream && remoteStream.getVideoTracks().length > 0) {
          console.log(
            "GOT VIDEO TRACK",
            remoteStream.id,
            incomingScreenShare.id,
            "peer id"
          );
          console.log(incomingScreenShare.id, "incomingScreenShare.id");
          setIncomingScreenVideo({
            remoteStream,
            id: incomingScreenShare.id,
            name: incomingScreenShare.name,
          });
          setMainItem({
            remoteStream,
            id: incomingScreenShare.id,
            name: `${incomingScreenShare.name}'s screen`,
            isVideoOn: true,
            isAudioOn: true,
          });

          toast(`${incomingScreenShare.name} is sharing screen`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Zoom,
          });
        } else {
          console.log("Got non-video track");
        }
      });
    }
  }, [incomingScreenShare]);

  const toggleVideo = async () => {
    socket.emit("videoOn", { isVideoOn: !isVideoOn });
    setIsVideoOn((prevState) => !prevState);
    myStream.getVideoTracks().forEach((track) => (track.enabled = !isVideoOn));
  };

  const toggleAudio = () => {
    socket.emit("AudioOn", { isAudioOn: !isAudioOn });
    setIsAudioOn((prevState) => !prevState);
    myStream.getAudioTracks().forEach((track) => {
      track.enabled = !isAudioOn;
    });
  };
  function getCurrentTimeFormatted() {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedTime = `${hours}.${minutes} ${ampm}`;
    return formattedTime;
  }
  const sendMessage = (message) => {
    console.log(message);
    setMessages((prev) => [
      ...prev,
      { id: 1, time: getCurrentTimeFormatted(), name: "Me", message: message },
    ]);
    socket.emit("sendMessage", { token: state, message: message });
  };

  const chatClicked = () => {
    setShowChatModal(true);
  };
  useEffect(() => {
    setReadMessageCount(messages.length);
  }, [showChatModal]);
  const shareMyScreen = useCallback(async () => {
    navigator.mediaDevices
      .getDisplayMedia({ video: true })
      .then((stream) => {
        setMyScreenShare(stream);
        setIsScreenShareOn(true);

        socket.emit("usersInThisRoom", { room: roomId });
        stream.getVideoTracks()[0].onended = function () {
          console.log("Inactive:");
          stopMyScreen();
        };
      })
      .catch((error) => {
        console.error("Error getting screen share:", error);
      });
  }, [socket, roomId, setMyScreenShare]);

  const stopMyScreen = useCallback(async () => {
    socket.emit("stopMyScreen", { room: roomId });
    myScreenSharePeerConnections.forEach(async (peer) => {
      console.log("cc");
      await peer.peer.peer.close();
    });
    myScreenSharePeerConnections.forEach(async (peer) => {
      myScreenSharePeerConnections.pop();
    });
    setMyScreenShare((prev) => null);
    setIsScreenShareOn(false);
  }, [socket, myScreenShare, isScreenShareOn, myScreenSharePeerConnections]);

  const checkOne = async () => {
    console.log(remoteStreams, peerConnections);
    socket.emit("checkOne");
    const updatedRemoteStreams = [
      { ...remoteStreams[0], name: "samasdada" },
      ...remoteStreams.slice(1),
    ];

    setRemoteStreams(updatedRemoteStreams);
  };

  const handleUserToggleVideo = ({ socketId, isVideoOn }) => {
    console.log(socketId, isVideoOn);
    console.log(mainItem);
    setMainItem((prev) => {
      if (prev.id == socketId) {
        return { ...prev, isVideoOn: isVideoOn };
      } else {
        return prev;
      }
    });
    setRemoteStreams((prevRemoteStreams) => {
      return prevRemoteStreams.map((remoteStream) => {
        if (remoteStream.id == socketId) {
          return { ...remoteStream, isVideoOn: isVideoOn };
        }
        return remoteStream;
      });
    });
  };

  const handleUserToggleAudio = ({ socketId, isAudioOn }) => {
    setMainItem((prev) => {
      if (prev.id == socketId) {
        return { ...prev, isAudioOn: isAudioOn };
      } else {
        return prev;
      }
    });
    console.log(socketId, isAudioOn);
    // if (mainItem.id === socketId) {
    //   setMainItem({ ...mainItem, isAudioOn: isAudioOn });
    // }
    setRemoteStreams((prevRemoteStreams) => {
      return prevRemoteStreams.map((remoteStream) => {
        if (remoteStream.id == socketId) {
          return { ...remoteStream, isAudioOn: isAudioOn };
        }
        return remoteStream;
      });
    });
  };

  const handleReceiveMessage = ({ socketId, name, message }) => {
    console.log(socketId, name, message);
    setMessages((prev) => [
      ...prev,
      {
        id: socketId,
        time: getCurrentTimeFormatted(),
        name: name,
        message: message,
      },
    ]);
  };
  //!new
  const [mainItem, setMainItem] = useState({});
  const [maxItemCountInMainItem, setMaxItemCountInMainItem] = useState(0);
  const [startingIndexInMainPage, setStartingIndexInMainPage] = useState(0);

  const [displayItems, setDisplayItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [showNextItemsButton, setShowNextItemsButton] = useState(false);
  const [showPreviousItemsButton, setShowPreviousItemsButton] = useState(false);

  const middleContainerRef = useRef(null);
  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  });

  const updateContainerDimensions = () => {
    if (middleContainerRef.current) {
      setContainerDimensions({
        width: middleContainerRef.current.offsetWidth - 60,
        height: middleContainerRef.current.offsetHeight - 10,
      });
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateContainerDimensions);
    updateContainerDimensions();

    return () =>
      window.removeEventListener("resize", updateContainerDimensions);
  }, []);

  const itemWidth = useMemo(() => {
    if (remoteStreams.length > 23) return 110;
    if (remoteStreams.length > 17) return 150;
    if (remoteStreams.length > 9) return 200;
    if (remoteStreams.length > 3) return 250;
    return 250;
  }, [remoteStreams.length]);

  const itemHeight = useMemo(() => itemWidth * 0.6, [itemWidth]);

  const maxItemsCount = useMemo(() => {
    const itemsPerRow = Math.floor(
      containerDimensions.width / (itemWidth + 10)
    );
    const rows = Math.floor(containerDimensions.height / (itemHeight + 10));
    return itemsPerRow * rows;
  }, [containerDimensions, itemWidth, itemHeight]);

  const itemsPerPage = maxItemsCount;

  useEffect(() => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setDisplayItems(remoteStreams.slice(startIndex, endIndex));

    setShowNextItemsButton(endIndex < remoteStreams.length);
    setShowPreviousItemsButton(startIndex > 0);
  }, [currentPage, itemsPerPage, remoteStreams]);

  useEffect(() => {
    const rows = Math.floor(containerDimensions.height / (150 + 10));
    setMaxItemCountInMainItem(rows);
  }, [containerDimensions]);

  const itemStyle = {
    display: "flex",
    width: `${itemWidth}px`,
    height: `${itemHeight}px`,
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #000",
    overflow: "hidden",
    position: "relative",
  };

  useEffect(() => {
    window.addEventListener("popstate", (e) => {
      window.history.go(1);
    });
  }, []);

  const styles = {
    display: "table-cell",
    height: "60px",
    width: "80px",
    textAlign: "center",
    background: "#f6f6f6",
    verticalAlign: "middle",
    border: "5px solid white",
  };

  const clickedCopy = () => {
    console.log("copied");
    toast(" 📄 Invitation copied", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Zoom,
    });
  };

  const leaveMeeting = () => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0f2d52",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, leave!",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = `/lobby/${roomId}`;
      }
    });
  };

  return (
    // <div>
    //   <h1>Room Page</h1>
    //   <button onClick={toggleVideo}>
    //     {isVideoOn ? "Turn Off Video" : "Turn On Video"}
    //   </button>
    //   <button onClick={toggleAudio}>
    //     {isAudioOn ? "Turn Off Audio" : "Turn On Audio"}
    //   </button>
    //   {isScreenShareOn == false && !incomingScreenVideo && (
    //     <button onClick={shareMyScreen}>Share My Screen</button>
    //   )}
    //   <button onClick={checkOne}>check</button>
    //   <h1>My Stream</h1>
    //   <div style={{ display: "flex" }}>
    //     {myStream && (
    //       <>
    //         <ReactPlayer
    //           playing
    //           muted
    //           height="100px"
    //           width="200px"
    //           url={myStream}
    //         />
    //       </>
    //     )}

    //     {myScreenShare && (
    //       <div>
    //         <div>My screen share</div>
    //         <ReactPlayer
    //           playing
    //           muted
    //           height="100px"
    //           width="200px"
    //           url={myScreenShare}
    //         />
    //       </div>
    //     )}

    //     {incomingScreenVideo && (
    //       <div>
    //         <div>incoming screen share {incomingScreenVideo.name}</div>
    //         <ReactPlayer
    //           playing
    //           muted
    //           height="200px"
    //           width="400px"
    //           url={incomingScreenVideo.remoteStream}
    //         />
    //       </div>
    //     )}
    //   </div>

    //   <h1>Remote Stream</h1>
    //   <div style={{ display: "flex" }}>
    //     {remoteStreams.map((stream, index) => (
    //       <div key={index}>
    //         <div>{stream.name}</div>
    //         {stream.isVideoOn && <div>videoOn</div>}
    //         {stream.isAudioOn && <div>AudioOn</div>}
    //         <div>
    //           <ReactPlayer
    //             playing
    //             height="100px"
    //             width="200px"
    //             url={stream.remoteStream}
    //           />
    //         </div>
    //       </div>
    //     ))}
    //   </div>
    // </div>

    <div
      className="room_container"
      style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      {showChatModal && (
        <div>
          <ChatModal
            setShowChatModal={setShowChatModal}
            messages={messages}
            setMessages={setMessages}
            sendMessage={(message) => sendMessage(message)}
            // getMeetingData={getMeetingData}
          />
        </div>
      )}

      <div className="top_room_container d-flex align-items-center justify-content-between">
        <div style={{ fontWeight: "bold", color: "gray" }}>
          {meetingData.title}
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <div>
            <LuUsers2 size={20} />
          </div>
          <div
            style={{ fontSize: "14px", marginTop: "3px", marginLeft: "2px" }}>
            {remoteStreams.length + 1}
          </div>
        </div>
      </div>
      <div className="middle_room_container" ref={middleContainerRef}>
        {remoteStreams.map((stream, index) => (
          <div style={{ display: "none" }} key={index}>
            <ReactPlayer playing url={stream.remoteStream} />
          </div>
        ))}
        {Object.keys(mainItem).length === 0 && (
          <div
            style={{
              height: "100%",
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
              position: "relative",
            }}>
            {" "}
            {showNextItemsButton && (
              <PaginationIcon
                side="right"
                onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
              />
            )}
            {showPreviousItemsButton && (
              <PaginationIcon
                side="left"
                onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
              />
            )}
            {myStream && (
              <>
                {" "}
                <div
                  style={
                    displayItems.length == 0 && !myScreenShare
                      ? {
                          height: `${containerDimensions.height}px`,
                          width: `${containerDimensions.height * 1.6}px`,
                        }
                      : itemStyle
                  }>
                  {" "}
                  {displayItems.length !== 0 && (
                    <>
                      <div
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          left: "3px",
                          fontSize: "14px",
                        }}>
                        Me
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          bottom: "3px",
                          right: "5px",
                          display: "flex",
                          gap: "3px",
                        }}>
                        <div>{!isAudioOn && <IoMicOffOutline size={16} />}</div>
                        <div>
                          {!isVideoOn && <IoVideocamOffOutline size={16} />}
                        </div>{" "}
                      </div>
                    </>
                  )}
                  <ReactPlayer
                    playing
                    muted
                    height={""}
                    width={""}
                    url={myStream}
                  />
                  {/* {item.name} */}
                </div>
              </>
            )}
            {}
            {myScreenShare && (
              <>
                <div style={itemStyle}>
                  <ReactPlayer
                    playing
                    muted
                    height={""}
                    width={""}
                    url={myScreenShare}
                  />{" "}
                  <div
                    style={{
                      position: "absolute",
                      bottom: "0px",
                      left: "3px",
                      fontSize: "14px",
                    }}>
                    My screen share
                  </div>
                </div>
              </>
            )}
            {incomingScreenVideo && (
              <>
                <div className="list_view_item" style={itemStyle}>
                  {" "}
                  <div
                    style={{
                      position: "absolute",
                      bottom: "0px",
                      left: "4px",
                      fontSize: "14px",
                    }}>
                    {`${incomingScreenVideo.name}'s screen`}
                  </div>
                  <div
                    onClick={() => setMainItem(incomingScreenVideo)}
                    className="list_view_full_screen"
                    style={{
                      display: "none",
                      position: "absolute",
                      top: "2px",
                      right: "2px",
                      fontSize: "14px",
                      zIndex: "1",
                    }}>
                    <MdFullscreen size={20} />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "3px",
                      right: "5px",
                      display: "flex",
                      gap: "3px",
                    }}></div>
                  <ReactPlayer
                    playing
                    // height={"500%"}
                    url={incomingScreenVideo.remoteStream}
                  />
                  {/* {item.name} */}
                </div>
              </>
            )}
            {displayItems.map((item, index) => (
              <div className="list_view_item" key={index} style={itemStyle}>
                {" "}
                <div
                  style={{
                    position: "absolute",
                    bottom: `${item.isVideoOn ? "0px" : ""}`,
                    left: `${item.isVideoOn ? "4px" : ""}`,
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    right: `${item.isVideoOn ? "50px" : ""}`,
                  }}>
                  {item.name}
                </div>
                <div
                  onClick={() => setMainItem(item)}
                  className="list_view_full_screen"
                  style={{
                    display: "none",
                    position: "absolute",
                    top: "2px",
                    right: "2px",
                    fontSize: "14px",
                    zIndex: "1",
                  }}>
                  <MdFullscreen size={20} />
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "3px",
                    right: "5px",
                    display: "flex",
                    gap: "3px",
                  }}>
                  <div>{!item.isAudioOn && <IoMicOffOutline size={16} />}</div>
                  <div>
                    {!item.isVideoOn && <IoVideocamOffOutline size={16} />}
                  </div>{" "}
                </div>
                <ReactPlayer
                  playing
                  muted
                  // height={"500%"}
                  url={item.remoteStream}
                />
                {/* {item.name} */}
              </div>
            ))}
          </div>
        )}
        {
          //! {focus mod2}
        }

        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",

              width: "100%",
            }}>
            <div
              style={{
                position: "relative",
                // width: "100%",
                // height: "100%",
                height: `${containerDimensions.height}px`,
                width: `${containerDimensions.height * 1.5}px`,
              }}>
              <div
                style={{ position: "absolute", bottom: "5px", left: "50px" }}>
                {mainItem.name}
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: "5px",
                  right: "50px",
                  display: "flex",
                  gap: "3px",
                }}>
                <div>
                  {mainItem && !mainItem.isAudioOn && (
                    <IoMicOffOutline size={16} />
                  )}
                </div>
                <div>
                  {!mainItem.isVideoOn && <IoVideocamOffOutline size={16} />}
                </div>{" "}
              </div>
              <ReactPlayer
                playing
                muted
                width={"98%"}
                height={"98%"}
                // style={{ width: "100%", height: "100%" }}
                // height={`${containerDimensions.height}px`}
                url={mainItem.remoteStream}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="bottom_room_container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "8px 40px",
        }}>
        <div>
          <div>
            {" "}
            <CopyMeetingId
              clickedCopy={clickedCopy}
              meetingData={meetingData}
              meetingId={roomId}
            />
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-center align-items-center gap-3">
            <MeetingActionButton
              tooltipText={`turn ${isAudioOn ? "off" : "on"} audio`}
              onClick={toggleAudio}
              disabled={!isAudioOn}
              action={"audio"}
            />
            <MeetingActionButton
              tooltipText={`turn ${isVideoOn ? "off" : "on"} video`}
              onClick={toggleVideo}
              disabled={!isVideoOn}
              action={"video"}
            />
            {isScreenShareOn == false && !incomingScreenVideo && (
              <MeetingActionButton
                onClick={shareMyScreen}
                action={"screenShare"}
                tooltipText={`Screen share`}
              />
            )}

            <MeetingActionButton
              onClick={chatClicked}
              tooltipText={`Chat`}
              action={"message"}
              showDot={readMessageCount !== messages.length ? true : false}
            />
            <MeetingActionButton
              tooltipText={`Coming soon!`}
              action={"react"}
            />
            {Object.keys(mainItem).length !== 0 && (
              <MeetingActionButton
                action={"listView"}
                tooltipText={`List view`}
                onClick={() => setMainItem({})}
              />
            )}
          </div>
        </div>
        <div>
          <div
            onClick={leaveMeeting}
            className="leave_button d-flex justify-content-center align-items-center">
            <div>Leave</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomPage;
