import React, { useState } from 'react'
import { FaAngleRight } from "react-icons/fa6";
import MeetingCard from '../meetingCard/MeetingCard';
const MeetingDates = ({ date,meetings }) => {
const [showDetails, setShowDetails] = useState(date === "Reusable Meetings"?true:false);
  return (
    <div>
      <div className=" d-flex justify-content-center mt-2">
        <div
          onClick={() => setShowDetails(!showDetails)}
          style={{
            width: "500px",
            display: "flex",
            alignItems: "center",
            gap: "6px",
            fontSize: "15px",
            cursor: "pointer",
          }}>
          <FaAngleRight style={{ rotate: "90deg" }} />
          {date}
        </div>
      </div>
      {meetings &&
        showDetails &&
        meetings.map((meeting, index) => (
          <div key={index}>
            <MeetingCard meeting={meeting} />
          </div>
        ))}
    </div>
  );
};

export default MeetingDates