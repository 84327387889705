import React, { useEffect, useRef, useState } from "react";
import { FiSend } from "react-icons/fi";
import "./chatModal.css";
import LeftMessage from "../leftMessage/LeftMessage";
import RightMessage from "../rightMessage/RightMessage";
import { IoClose } from "react-icons/io5";

import { RiMessage2Line } from "react-icons/ri";
import TextInputLight from "../TextInputLight/TextInputLight";

const ChatModal = ({ setShowChatModal, messages, sendMessage }) => {
  const [message, setMessage] = useState("");
  const messagesContainerRef = useRef(null);
  let currentUserId;
  let showUserData;
  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);
  return (
    <div>
      <div
        onClick={() => setShowChatModal(false)}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
          overflow: "auto",
          cursor: "pointer",
        }}>
        <div
          onClick={(e) => e.stopPropagation()}
          style={{
            cursor: "default",
            backgroundColor: "white",
            // padding: "0px 10px",
            borderRadius: "6px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            width: "500px",
            height: "85%",
            position: "relative",
          }}>
          <div
            className=" d-flex  justify-content-between align-items-center ps-3 pe-2"
            style={{
              borderBottom: "2px solid #e9ecf3",
              height: "35px",
              color: "black",
              backgroundColor: "#ffffff",
              borderRadius: "5px 5px 0 0",
            }}>
            <div
              className=" d-flex justify-content-center align-items-center gap-1"
              style={{ fontWeight: "bold", fontFamily: "font5" }}>
              <RiMessage2Line />
              <div>Chat</div>
            </div>
            <div
              className=" d-flex justify-content-center align-items-center close_chat"
              onClick={() => setShowChatModal(false)}
              style={{
                fontSize: "20px",
                cursor: "pointer",
                // backgroundColor: "red",
                padding: "2px",
                filter: "blur",
                borderRadius: "3px",
                transition: "0.2s ease-out",
              }}>
              <IoClose />
            </div>
          </div>
          <div
            className="chat_container"
            ref={messagesContainerRef}
            style={{
              overflowX: "auto",
              width: "500px",
              color: "black",
              padding: "5px ",
            }}>
            {messages &&
              messages.map((message, index) => {
                showUserData = currentUserId !== message.id;
                currentUserId = message.id;
                if (message.id == 1) {
                  return (
                    <div key={index}>
                      <RightMessage
                        message={message}
                        showUserData={showUserData}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={index}>
                      <LeftMessage
                        message={message}
                        showUserData={showUserData}
                      />
                    </div>
                  );
                }
              })}
          </div>
          <div
            style={{
              color: "black",
              position: "absolute",
              bottom: "0px",
              height: "50px",
              width: "100%",
              display: "flex",
              padding: "5px",
              // backgroundColor:"gray",
            }}>
            <div
              style={{
                border: "2px solid #bababa",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                borderRadius: "20px",
              }}>
              <input
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="chat_input"
                placeholder="Write your message here ..."
                type="text"
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    console.log("Enter");
                    setMessage("");
                    sendMessage(event.target.value);
                  }
                }}
              />

              <div
                onClick={() => {
                  sendMessage(message);
                  setMessage("");
                }}
                style={{
                  marginRight: "6px",
                  backgroundColor: "#f2f2f2",
                  height: "30px",
                  width: "32px",
                  borderRadius: "50%",
                  transition: "0.2s ease-out",
                  cursor: "pointer",
                }}
                className=" d-flex justify-content-center align-items-center chat_send_button">
                <div
                  style={{
                    fontSize: "16px",
                    marginRight: "1px",
                    marginBottom: "1px",
                  }}>
                  <FiSend />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatModal;
